.contactDetails #text-15 {
    padding-left: 100px;
    text-align: left;
    max-width: 300px;
    background-color: #000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0 20px 70px;
    padding: 50px 20px;
    position: relative;
    top: -20px;
}

.contactDetails img {
    border: 0;
    margin-right: 10px;
    width: 15px;
    object-fit: cover;
}

.contactDetails #text-15 .textwidget>p>div {
    border-bottom: 1px dotted #f7f7f7;
    margin-bottom: 20px;
    padding-bottom: 10px;

}

.contactDetails #text-15 a {
    color: #f7f7f7;
    text-decoration: none;
}

.contactDetails #text-15 .textwidget>p>div:last-child {
    margin-bottom: 0px;
    padding-bottom: 10px;
}